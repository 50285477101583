import React from "react"
import styled from "@emotion/styled"

import Typography from "../../components/typography"
import colors from "../../styles/colors"
import { useRoiContext } from "../../contexts/roiContext"
import Global from "../../styles/global"
import Card from "../../components/card"
import { Select } from "../../components/select"
import Button, { BigPillButton } from "../../components/button"
import Link from "../../components/link"
import { AverageSalaryWrapper } from "../../components/roi/roiCalculator"
import { SalaryInput } from "../../components/roi/salaryInput"
import SEO from "../../components/seo"
import Input from "../../components/input"
import DemoContext from "../../contexts/demo"
import { BackTo } from "../../components/backTo"

const ContentWrapper = styled.div`
  margin-top: 80px;
`

const Text = styled(Typography)`
  color: ${colors.darkgrey};
`
const CardTitle = styled(Typography)`
  color: ${colors.darkgrey};
  font-weight: 700;
  @media screen and (min-width: 769px) {
    font-size: 60px;
    line-height: 65px;
    margin-bottom: 3rem;
  }
`

const InputWrapper = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  flex: 1;
`

const StyledInput = styled(Input)`
  height: 42px;
  margin-top: 0;
  margin-bottom: 0;
`

const ProductCategorySelect = styled(Select)`
  margin-top: 2rem;
  width: 100%;
  @media screen and (min-width: 769px) {
    margin-top: 4rem;
    max-width: 300px;
  }
`

const CardMaxWidth = styled(Card)`
  max-width: 855px;
  @media screen and (min-width: 769px) {
    text-align: center;
    align-items: center;
  }
`

const StyledUL = styled.ul`
  & > *:not(:first-child) {
    padding-top: 16px;
  }
`

const RoiInputPage = ({ location }) => {
  const {
    stepDone,
    supportRequests,
    // @ts-expect-error not typed context
    serviceAgents,
    // @ts-expect-error not typed context
    currency,
    // @ts-expect-error not typed context
    averageSalary,
    // @ts-expect-error not typed context
    productCategory,
    // @ts-expect-error not typed context
    setSupportRequests,
    // @ts-expect-error not typed context
    setServiceAgents,
    // @ts-expect-error not typed context
    setCurrency,
    // @ts-expect-error not typed context
    setAverageSalary,
    // @ts-expect-error not typed context
    setProductCategory,
  } = useRoiContext()

  const { open: openDemo } = React.useContext(DemoContext)

  return (
    <>
      <BackTo linkTo="roi" variant="dark" />
      <SEO title="Input data" enableCookiebot />
      <Global bg="white" />

      <ContentWrapper>
        <CardMaxWidth variant="lightgrey" noLateralPadding>
          <CardTitle variant="h2">How to use this calculator</CardTitle>
          <Text
            variant="bodyLarge"
            style={{ marginTop: "2rem", textAlign: "left" }}
          >
            Support automation drives multiple positive business outcomes, the
            most important of which is increased efficiency. The more inquiries
            are resolved through automation, the more time and money your
            company will save.
            <br />
            <br />
            Our team created a model to help businesses understand how much they
            could save by running their self-service support with Mavenoid. We
            use industry benchmarks and internal data to estimate the projected
            ROI.
            <br />
            <br />
            Use it for preliminary evaluation, and contact our team to
            collaborate with hardware support experts on developing a more
            robust and detailed business case.
            <br />
            <br />A few things to keep in mind before you start using the ROI
            calculator:
          </Text>
          <Text variant="bodyLarge" style={{ textAlign: "left" }}>
            <StyledUL>
              <li>
                We are going to ask you four questions around your customer
                request volume, the number of support agents, cost per agent,
                and product category as inputs for the ROI model.
              </li>
              <li>
                Don't worry, if you don't have all the data - use ballpark
                numbers to get a quick estimate. You can refine it later when
                working with the Mavenoid team.
              </li>
              <li>
                You can use one product line or use case as an example for
                simplicity. Alternatively, you can use the default settings to
                see what ROI can look like.
              </li>
              <li>
                After you fill out all of the information, we will show you the
                calculation results on your screen. The results will also be
                emailed to you, allowing you to share and discuss them with your
                team.
              </li>
            </StyledUL>
          </Text>
        </CardMaxWidth>
        <CardMaxWidth variant="lightgrey">
          <CardTitle variant="h2">
            How many support requests do you have every month?
          </CardTitle>
          <Text
            variant="bodyLarge"
            style={{ fontWeight: "bold", marginTop: "2rem" }}
          >
            This will help us understand the scale of support in your business.
          </Text>
          <Text variant="bodyLarge">
            Tip: consider requests from all channels (email, phone calls, chat,
            etc)
          </Text>
          <InputWrapper>
            <StyledInput
              onChange={e => setSupportRequests(e.target.value)}
              variant="white"
              focusBorderVariant="primary"
              value={supportRequests}
              type="number"
            />
          </InputWrapper>
        </CardMaxWidth>
        <CardMaxWidth variant="lightgrey">
          <CardTitle variant="h2">
            How many service agents do you have?
          </CardTitle>
          <Text
            variant="bodyLarge"
            style={{ fontWeight: "bold", marginTop: "2rem" }}
          >
            This will help us understand your team size and their efficiency.
          </Text>
          <Text variant="bodyLarge">
            Tip: consider full-time equivalents for a more accurate
            calculatation (e.g. two part time agents = one full-time agent)
          </Text>
          <InputWrapper>
            <StyledInput
              onChange={e => setServiceAgents(e.target.value)}
              variant="white"
              focusBorderVariant="primary"
              value={serviceAgents}
              type="number"
            />
          </InputWrapper>
        </CardMaxWidth>
        <CardMaxWidth variant="lightgrey">
          <CardTitle variant="h2">
            What's the average salary of your support agent?
          </CardTitle>
          <Text
            variant="bodyLarge"
            style={{ fontWeight: "bold", marginTop: "2rem" }}
          >
            This will help us understand your current support costs.
          </Text>
          <Text variant="bodyLarge" style={{ marginBottom: "3rem" }}>
            Tip: use average gross monthly salary, including taxes, benefits,
            etc
          </Text>
          <AverageSalaryWrapper>
            <Select
              style={{
                marginRight: "1rem",
              }}
              defaultValue={currency}
              onChange={e => setCurrency(e.target.value)}
            >
              <option value="eur">€ EUR</option>
              <option value="usd">$ USD</option>
            </Select>
            <SalaryInput
              onChange={e => setAverageSalary(e.target.value)}
              variant="white"
              focusBorderVariant="primary"
              value={averageSalary}
              type="number"
            />
          </AverageSalaryWrapper>
        </CardMaxWidth>
        <CardMaxWidth variant="lightgrey">
          <CardTitle variant="h2">What's your product?</CardTitle>
          <Text
            variant="bodyLarge"
            style={{ fontWeight: "bold", marginTop: "2rem" }}
          >
            This will help us use the right benchmarks for your industry.
          </Text>
          <Text variant="bodyLarge" style={{}}>
            Tip: select the primary type of products you manufacture and / or
            support
          </Text>
          <ProductCategorySelect
            onChange={e => setProductCategory(e.target.value)}
            defaultValue={productCategory}
            style={{ height: "40px" }}
          >
            <option disabled selected>
              Product categories
            </option>
            <option value="consumer-electronics">Consumer electronics</option>
            <option value="household-applicances">Household appliances</option>
            <option value="gardening-equipment">Gardening equipment</option>
            <option value="industrial-equipments">Industrial equipments</option>
            <option value="software">Software</option>
            <option value="vehicle">Vehicle</option>
            <option value="other">Other</option>
          </ProductCategorySelect>
          <BigPillButton
            to="/roi-new/details"
            as={Link}
            onClick={() => {
              stepDone("ROI_INPUTS", {
                supportRequests,
                serviceAgents,
                currency,
                averageSalary,
                productCategory,
              })
            }}
            variant="primary"
            style={{ marginTop: "2rem", height: "90px" }}
          >
            Calculate my ROI estimate
          </BigPillButton>
        </CardMaxWidth>
        <CardMaxWidth center variant="lightgrey" noMinHeight>
          <Text variant="bodySmall">
            Want us to build you a fully custom estimate? <br /> Request time
            with our hardware support experts.
          </Text>
          <Button
            onClick={openDemo}
            style={{ marginTop: "2rem", textTransform: "none" }}
          >
            Request custom estimate
          </Button>
        </CardMaxWidth>
      </ContentWrapper>
    </>
  )
}

export default RoiInputPage
