import styled from "@emotion/styled"
import React from "react"
import colors from "../styles/colors"
import { typographyStyles } from "./typography"

const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: ${colors.white};
  border-radius: 1000px;
  border: 2px solid transparent;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  position: relative;
`

const SelectElem = styled.select`
  ${typographyStyles.bodyLarge};
  height: 100%;
  width: 100%;
  appearance: none;
  border: 0;
  outline: none;
  background-color: transparent;
  padding-right: 2rem;

  &:focus {
    border-color: ${colors.primary};
  }
`

const Chevron = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22px;
  width: 22px;
  background-color: ${colors.darkgrey};

  border-radius: 1000px;
  pointer-events: none;

  position: absolute;
  right: 1.5rem;
`

export const Select = ({
  size = "LARGE",
  children,
  className,
  style,
  ...props
}) => {
  return (
    <SelectWrapper className={className} style={style}>
      <SelectElem {...props} size={size}>
        {children}
      </SelectElem>
      <Chevron>
        <svg
          width="22"
          height="22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="11" cy="11" r="11" fill="#333" />
          <path
            fill="#fff"
            d="M11.052 15.023l-1.037-1.037L15.2 8.801l1.037 1.037z"
          />
          <path
            fill="#fff"
            d="M5.868 9.838l1.037-1.037 5.186 5.185-1.038 1.037z"
          />
        </svg>
      </Chevron>
    </SelectWrapper>
  )
}
